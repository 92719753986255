import { PersistentBbuData } from 'LeadsBridgeApp'
import { axiosInterceptor } from '@app/services/axiosInterceptor'
import { endpoints, getApiErrorMessage } from '@app/api/config'
import { BbuParnersFullName } from '@app/enums/bridgeByUrl'
import { PersistentLocalStorageKeys } from '@app/enums/persistentLocalStorageKeys'

export const validatePersistentBbuData = (
  bbuData?: Partial<PersistentBbuData>
): boolean => {
  // wee need a try/catch statement to validate `new URL` constructor
  try {
    const isValidSourceLogo =
      bbuData?.source?.logo && new URL(bbuData?.source?.logo)
    const isValidDestinationLogo =
      bbuData?.destination?.logo && new URL(bbuData?.destination?.logo)
    const isValidRedirectUri =
      bbuData?.requestUri && new URL(bbuData.requestUri)
    return Boolean(
      isValidSourceLogo && isValidDestinationLogo && isValidRedirectUri
    )
  } catch {
    return false
  }
}

export const userStatusForSurvey = ['NEW', 'DRAFT']

export const retryButtonPartnerLink: Record<BbuParnersFullName, string> = {
  [BbuParnersFullName.tiktok]: 'https://ads.tiktok.com',
}

const setupBridge = async ({ requestUri }: { requestUri: string }) => {
  const axiosInstance = await axiosInterceptor()
  const apiResponse: string = await axiosInstance
    .post(endpoints.setupBridge, { requestUri: requestUri })
    .then((response) => response.data?.callbackPath)
  return apiResponse
}

// This function is just a simplified version of what we'll do in future.
// We still don't have partners with external flow (except FB, which is a custom Fbe)
// So we return always tru
export const checkIfExternalFlowIsRequiredOrSetupBridge = ({
  partnerFullName,
  onSetupBridgeError,
  requestUri,
}: {
  partnerFullName: BbuParnersFullName
  onSetupBridgeError: (errorMessage: string) => void
  requestUri: string
}) => {
  const isExternalFlowRequired = partnerFullName !== 'tiktok' // we'll add logic in future
  if (isExternalFlowRequired) {
    // do something (probably it's going to redirect user to a new route eg: /bbu/external-flow/:partnerName or /fbe
    return
  }

  // if external flow is not required (eg. tiktok)
  console.log(`Partner does not have external flow, let's setup the bridge`)
  setupBridge({ requestUri: requestUri })
    .then((callbackPath) => {
      window.sessionStorage.removeItem(
        PersistentLocalStorageKeys.bbuPersistentData
      )

      if (process.env.REACT_USE_MOCK_API === 'true') {
        console.log('Simulating redirect to: ' + callbackPath)
      } else {
        window.location.href = callbackPath || `${process.env.REACT_APP_V1_URL}`
      }
    })
    .catch((error) => {
      onSetupBridgeError(getApiErrorMessage({ error: error }))
    })
}

import {
  ApiAppSchemaField,
  ApiAppSchemaFieldTypes,
  ApiIntegration,
  ApiSaveIntegrationRequestBody,
  ApiSettingValue,
  ApiSettingAcceptedValues,
} from 'BackendApi'
import { IntegrationFormField, IntegrationFormValues } from 'Nbee'
import { PersistentSessionStorageKeys } from '@app/enums/persistentLocalStorageKeys'

const emptyValueByType: Record<
  ApiAppSchemaFieldTypes,
  ApiSettingAcceptedValues
> = {
  password: '',
  textarea: '',
  code: '',
  number: '',
  datetime: '',
  domain: '',
  dropdown: '',
  email: '',
  text: '',
  tags: [],
  url: '',
  subdomain: '',
  toggle: false,
  validate_email: '',
  validate_email_multi: '',
}

// we set a default value if api returns it only if field has not child, in this way we avoid infinite refetching loop
// if no default value is provided we set an empty value depending of the type of the field
const getDefaultFromApi = (field: ApiAppSchemaField) =>
  field.default || field.default === 0
    ? field.default
    : emptyValueByType[field.type] ?? ''

export const makeIntegrationFormItemFromApiSchemaField = ({
  field,
  existingField,
}: {
  field: ApiAppSchemaField
  existingField?: ApiSettingValue
}): IntegrationFormField => {
  // const existingIntegration
  return {
    id: field.id,
    value: existingField?.value ?? getDefaultFromApi(field),
    label: field.label,
    type: field.type,
    isRequired: field.required,
    selectValues: field.data,
    hide: field.hide,
    default: field.default,
    tooltip: field.tooltip,
    docUrl: field.docUrl,
    hasChild: field.hasChild,
    format: field.format,
    refreshable: field.refreshable,
  }
}

export const makeIntegrationFormInitialValues = (
  appAuthSchema: ApiAppSchemaField[],
  appName: string,
  existingIntegration?: ApiIntegration
): IntegrationFormValues => {
  return {
    name: existingIntegration?.name || '',
    credentials: appAuthSchema.map((field) => {
      const existingField = (existingIntegration?.credentials || []).find(
        ({ key }) => key === field.id
      )
      return makeIntegrationFormItemFromApiSchemaField({
        field: field,
        existingField: existingField,
      })
    }),
    ui: {
      existingIntegrationCredentials: existingIntegration?.credentials || [],
    },
  }
}

export const transformIntegrationFormValuesToApiSaveRequestBody = (
  formValues: IntegrationFormValues,
  appId: number
): ApiSaveIntegrationRequestBody => {
  /**
   * TRANSFORM EMPTY STRING ARRAYS INTO EMPTY ARRAYS
   * This function removes any array fields that only contain an empty string.
   * We add these empty strings to fields with email validation types (e.g. cc and bcc)
   * in order to render the corresponding Email Validation component.
   * However, the API does not accept these empty strings, so we remove them before
   * sending the form data to the API whenever users do not add any value to these fields.
   */

  const credentials = (formValues.credentials || []).map(
    ({ id, value, type }) => {
      if (
        type === 'validate_email_multi' &&
        Array.isArray(value) &&
        value.length === 1 &&
        value[0] === ''
      ) {
        return {
          key: id,
          value: [],
        }
      }
      return {
        key: id,
        value: value,
      }
    }
  )

  return {
    name: formValues.name,
    appId: appId,
    credentials: credentials,
  }
}

type RedirectParamsCredentials = Record<
  IntegrationFormField['id'],
  IntegrationFormField['value']
>

const encodeUriParamsForV1 = (obj: RedirectParamsCredentials) =>
  window.btoa(JSON.stringify(obj))

export const decodeUriParamsFromV1 = (encodedValue?: string) => {
  try {
    return JSON.parse(window.atob(encodedValue || '{}'))
  } catch {
    return null
  }
}

export const startOAuthFlow = ({
  credentials,
  redirectUri,
  appId,
  integrationId,
  isLegacy,
}: {
  credentials: IntegrationFormField[]
  redirectUri: string
  appId: number
  integrationId?: number
  isLegacy: boolean
}) => {
  // first thing we store the appId to session storage, so we can get it later
  sessionStorage.setItem(
    PersistentSessionStorageKeys.integrationAuthFlowAppId,
    `${appId}`
  )

  if (integrationId) {
    sessionStorage.setItem('instanceId', `${integrationId}`)
  }

  // ONLY LEGACY

  if (isLegacy) {
    // then we prepare an object to be encoded and sent as query string
    const credentialsAsObject = credentials.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: field.value,
      }
    }, {} as RedirectParamsCredentials)
    const encodedCredentials = encodeUriParamsForV1(credentialsAsObject)

    // we build the url and redirect the user to v1 who will create the integration
    // and will return a legacy integration id (v1)
    const uriHasQueryStringParams = redirectUri.includes('?')
    const oauthRedirectUri = `${redirectUri}${
      uriHasQueryStringParams ? '&' : '?'
    }providerId=${appId}&params=${encodedCredentials}`

    // if `integrationId` is passed it means we are editing an existing integration, so we need to add it to the redirect url
    // in this way v1 will now that is an edit and it won't create a new integration (SB-427)
    window.location.href = integrationId
      ? `${oauthRedirectUri}&instanceId=${integrationId}`
      : oauthRedirectUri
  } else {
    window.location.href = redirectUri
    /* window.location.href =
      '/integration/oauth2/callback?code=4/0AdQt8qiPlchf8d02DuQmZZsPgIpdPg6RerbPQFudHaKNpbZT0G4WAelBKCUiy-t8emtMJA&options=test&otherParam=test2' */
  }
}

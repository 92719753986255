import { StepBarStep } from '@components/StepBar'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { ApiBridgeAvailablePermissionCheckResponse } from 'BackendApi'
import { useFormikContext } from 'formik'
import { useAppDispatch } from '@app/store/hooks'
import { useUpdateBridge } from '@app/api/updateBridge'
import { useCreateNewBridge } from '@app/api/createNewBridge'
import {
  closeAlertMessage,
  sendToast,
} from '@app/store/actions/ApplicationConfigurationActions'
import { transformBridgeFormValuesToApiSaveBridgeBody } from '@app/api/utils/bridge'
import { BridgeFormValues } from 'Nbee'

export const makeNbeeSteps = (
  currentStepIndex?: number,
  bridgeId?: number | string
): StepBarStep[] => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const step1Route = bridgeId
    ? appRoutes.nbeeStep1Edit.makeUrl(`${bridgeId}`)
    : appRoutes.nbeeStep1New.makeUrl()

  const step2Route = bridgeId ? appRoutes.nbeeStep2.makeUrl(`${bridgeId}`) : '/'

  if (currentStepIndex === undefined) {
    return []
  }

  // STEP 1
  if (currentStepIndex === 0) {
    return [
      {
        title: t('nbee.bridgeBuilder.step1Title'),
        label: t('nbee.bridgeBuilder.step1Title'),
      },
      {
        title: t('nbee.bridgeBuilder.step2Title'),
        label: t('nbee.bridgeBuilder.step2Title'),
      },
      {
        title: t('nbee.bridgeBuilder.step3Title'),
        label: t('nbee.bridgeBuilder.step3Title'),
      },
    ]
  }

  // STEP 2
  if (currentStepIndex === 1) {
    return [
      {
        title: t('nbee.bridgeBuilder.step1Title'),
        label: t('nbee.bridgeBuilder.step1Title'),
        onStepClick: () => {
          navigate(step1Route)
        },
      },
      {
        title: t('nbee.bridgeBuilder.step2Title'),
        label: t('nbee.bridgeBuilder.step2Title'),
      },
      {
        title: t('nbee.bridgeBuilder.step3Title'),
        label: t('nbee.bridgeBuilder.step3Title'),
      },
    ]
  }

  // STEP 3
  if (currentStepIndex === 2) {
    return [
      {
        title: t('nbee.bridgeBuilder.step1Title'),
        label: t('nbee.bridgeBuilder.step1Title'),
        onStepClick: () => {
          navigate(step1Route)
        },
      },
      {
        title: t('nbee.bridgeBuilder.step2Title'),
        label: t('nbee.bridgeBuilder.step2Title'),
        onStepClick: () => {
          navigate(step2Route)
        },
      },
      {
        title: t('nbee.bridgeBuilder.step3Title'),
        label: t('nbee.bridgeBuilder.step3Title'),
      },
    ]
  }

  return []
}

// this function is used to calculate the initial state of the shouldCheckPermissions state in Step2/FieldsMappingForm
export const calculateInitialShouldCheckPermissionsState = (
  payload: ApiBridgeAvailablePermissionCheckResponse | undefined
): boolean => {
  const permissionChecklist = payload?.data.permissionChecklist
  const executeCheck = payload?.data.executeCheck

  if (permissionChecklist?.length !== 0 && executeCheck) {
    return true
  } else {
    return false
  }
}
export const useUpdateBridgeToPricing = (
  bridgeId?: string | number | undefined,
  reasonId?: string,
  minimumPlanRequired?: number
) => {
  const formik = useFormikContext<BridgeFormValues>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { mutate: updateBridge } = useUpdateBridge()
  const { mutate: createNewBridge } = useCreateNewBridge()

  const { prevPath } = location.state || { prevPath: '' }

  const handleError = () => {
    dispatch(
      sendToast({
        title: 'Error',
        message: 'Bridge update failed. Please try again.',
        color: 'negative',
      })
    )
  }

  const handleClick = () => {
    const formValues = formik.values
    const bridgeData = transformBridgeFormValuesToApiSaveBridgeBody(formValues)

    if (bridgeId) {
      updateBridge(
        {
          bridgeData: bridgeData,
          bridgeId: `${bridgeId}`,
        },
        {
          onSuccess: () => {
            navigate(
              appRoutes.pricing.makeUrlWithState(
                { prevPath: prevPath },
                reasonId,
                minimumPlanRequired
              )
            )
            dispatch(closeAlertMessage())
          },
          onError: handleError,
        }
      )
    } else {
      createNewBridge(bridgeData, {
        onSuccess: (data) => {
          const createdBridgeId = data?.data?.bridge?.id
          const newPath = appRoutes.nbeeStep1Edit.makeUrl(`${createdBridgeId}`)
          const searchStr = `?reason=${reasonId}&suggestPlan=${minimumPlanRequired}`
          navigate(`${appRoutes.pricing.path}${searchStr}`, {
            state: { prevPath: newPath },
          })
          dispatch(closeAlertMessage())
        },
        onError: handleError,
      })
    }
  }

  return handleClick
}

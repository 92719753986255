import { BridgeFormValues, MappedField } from 'Nbee'
import {
  ApiBridgeFieldDefinition,
  ApiTestFieldsMappingErrorItem,
  ApiTestFieldsMappingRequestBody,
  ApiTestableFieldsResponse,
} from 'BackendApi'
import { FormikErrors } from 'formik'

export const tranformApiResponseToFormValues = (
  apiResponse: ApiTestableFieldsResponse
) =>
  (apiResponse?.data || []).map((field) => ({
    id: field.id,
    label: field.label,
    value: field.lastValue,
  }))

// We use this function to adapt formik state to performa API post request
// to test fields mapping
export const transformFormValuesToTestMappingRequest = (
  fieldsMappingValues?: MappedField[]
): ApiTestFieldsMappingRequestBody =>
  (fieldsMappingValues || []).map((field) => ({
    destinationFieldId: field.destinationFieldId,
    destinationFieldType: field.destinationFieldType,
    mappingType: field.mappingType,
    destinationText: field.destinationText,
    sourceFieldId: field.sourceFieldId,
  }))

// transform api response (test fields mapping) into a formik error state
export const fromApiTestReponseToFormErrors = (
  destinationFields: ApiBridgeFieldDefinition[],
  apiResult: ApiTestFieldsMappingErrorItem[]
) => {
  const errors = destinationFields.map((field) => {
    const hasError = apiResult.find(
      ({ destinationFieldId }) => destinationFieldId === field.id
    )
    if (hasError) {
      return hasError.sourceFieldId
        ? {
            sourceFieldId: hasError.message,
          }
        : { destinationText: hasError.message }
    }
    return null
  })

  // some weird casting here since our error structure is more complex than formik built-in
  return {
    fieldsMapping: errors as any,
  } as FormikErrors<BridgeFormValues>
}
